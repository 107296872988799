export default {
	//中文
	msg: {
		msg1: '热线电话',
		msg2: '首页',
		msg3: '联系我们',
		msg4: '暂无数据',
		msg5: '电话',
		msg6: '邮箱',
		msg7: '材料列表>材料详情',
		msg8: '自定义尺寸',
		msg9: '请输入长度',
		msg10: '米',
		msg11: '添加',
		msg12: '删除',
		msg13: '请输入高度',
		msg14: '数量',
		msg15: '应付金额',
		msg16: '申请购买',
		msg17: '产品介绍',
		msg18: '联系地址',
		msg19: '收货人',
		msg20: '请输入收货人',
		msg21: '男',
		msg22: '女',
		msg23: '联系电话',
		msg24: '请输入联系电话',
		msg25: '是否需要送货',
		msg26: '是',
		msg27: '否',
		msg28: '所在地区',
		msg29: '请选择',
		msg30: '详细地址',
		msg31: '请填写详细地址：如道路、门牌号、小区、楼栋号等',
		msg32: '留言',
		msg33: '选填：填写内容已和卖家协商确认',
		msg34: '提交',
		msg35: '订单成功',
		msg36: '您的申请已提交成功，稍后工作人员会与您联系',
		msg37: '联系我们',
		msg38: '提交时间',
		msg39: '返回首页',
		msg40: '产品列表',
		msg41: '窗户',
		msg42: '材料',
		msg43: '查看更多',
		msg44: '产品列表>产品详情',
		msg45: '颜色',
		msg46: '选择',
		msg47: '请选择',
		msg48: '窗形',
		msg49: '铝合金',
		msg50: '自定义尺寸',
		msg51: '请输入高度',
		msg52: '请输入宽度',
		msg53: '是否需要玻璃',
		msg54: '玻璃名称',
		msg55: '请选择',
		msg56: '配件',
		msg57: '数量',
		msg58: '应付金额',
		msg59: '申请购买',
		msg60: '产品介绍',
		msg61: '选择颜色',
		msg62: '关闭',
		msg63: '确认',
		msg64: '请输入搜索关键字',
		msg65: '搜索',
		msg66: '$/米',
		msg67: '已选择',
		msg68: '选择',
		msg69: '选择窗形',
		msg70: '选择铝合金',
		msg71: '选择玻璃',
		msg72: '请输入收货人',
		msg73: '请输入联系电话',
		msg74: '返回首页',
		msg75:  "件",
		msg76:  "数量最少1个",
		msg77:  "请先选择规格",
		msg78:  "请输入自定义尺寸的长度",
		msg79:  "请输入自定义尺寸的宽度",
		msg80:  "请输入自定义尺寸的高度",
		msg81:  "请输入收货人",
		msg82:  "请输入联系电话",
		msg83:  "请选择所在地区",
		msg84:  "请填写详细地址",
		msg85:  "请选择颜色",
		msg86:  "请选择窗形",
		msg87:  "请选择铝合金",
		msg88:  "请选择玻璃",
		msg89:  "全选",
		
		// s1模块翻译
		msg90:  "费用",
		msg91:  "请选择",
		msg92:  "购物车",
		msg93:  "退出登录",
		msg94:  "登录/注册",
		msg95:  "退出成功",
		msg96:  "个人中心",
		msg97:  "我的资料",
		msg98:  "我的订单",
		msg99:  "地址管理",
		msg100:  "关于我们",
		msg101:  "设置",
		msg102:  "修改密码",
		msg103:  "修改邮箱",
		msg104:  "隐私协议",
		msg105:  "用户协议",
		msg106:  "新增地址",
		msg107:  "收货人姓名",
		msg108:  "请输入收货人姓名",
		msg109:  "先生",
		msg110:  "女士",
		msg111:  "手机",
		msg112:  "请输入",
		msg113:  "所在地区",
		msg114:  "请选择",
		msg115:  "详细地址",
		msg116:  "请填写详细地址：如道路、门牌号、小区、楼栋号等",
		msg117:  "设置为默认地址",
		msg118:  "保存",
		msg119:  "删除",
		msg120:  "提示",
		msg121:  "确定删除该地址吗",
		msg122:  "取消",
		msg123:  "确定",
		msg124:  "请输入收货人姓名",
		msg125:  "请输入正确的手机号",
		msg126:  "请选择地区",
		msg127:  "请输入详细地址",
		msg128:  "提交成功",
		msg129:  "编辑成功",
		msg130:  "删除成功",
		msg131:  "地址管理",
		msg132:  "新增地址",
		msg133:  "名称",
		msg134:  "修改",
		msg135:  "暂无数据",
		msg136:  "修改密码",
		msg137:  "修改方式",
		msg138:  "旧密码验证修改",
		msg139:  "邮箱验证码修改",
		msg140:  "旧密码",
		msg141:  "请输入旧密码",
		msg142:  "新密码",
		msg143:  "请输入新密码",
		msg144:  "确认新密码",
		msg145:  "请输入确认密码",
		msg146:  "我们要对您绑定的邮箱进行验证",
		msg147:  "验证码",
		msg148:  "请输入验证码",
		msg149:  "后重新获取",
		msg150:  "获取验证码",
		msg151:  "完成",
		msg152:  "请输入旧密码",
		msg153:  "请输入新密码",
		msg154:  "请输入确认密码",
		msg155:  "两次密码输入不正确",
		msg156:  "修改邮箱",
		msg157:  "我们要对您绑定的邮箱进行验证",
		msg158:  "新邮箱",
		msg159:  "请输入邮箱号码",
		msg160:  "验证码",
		msg161:  "请输入正确邮箱",
		msg162:  "修改成功",
		msg163:  "首页",
		msg164:  "购物车",
		msg165:  "全部商品",
		msg166:  "商品信息",
		msg167:  "单价",
		msg168:  "数量",
		msg169:  "金额",
		msg170:  "操作",
		msg171:  "/个",
		msg172:  "全选",
		msg173:  "去结算",
		msg174:  "合计",
		msg175:  "已选商品",
		msg176:  "件",
		msg177:  "商品数量已经最小了",
		msg178:  "操作成功",
		msg179:  "请先选择要删除的商品",
		msg180:  "请先选择要结算的商品",
		msg181:  "请输入邮箱",
		msg182:  "请输入验证码",
		msg183:  "请输入密码(6~12位字母+数字)",
		msg184:  "验证码登录",
		msg185:  "密码登录",
		msg186:  "登录",
		msg187:  "注册账号",
		msg188:  "忘记密码",
		msg189:  "注册",
		msg190:  "已有账号，前去",
		msg191:  "《用户注册协议》",
		msg192:  "与",
		msg193:  "《隐私政策》",
		msg194:  "我同意",
		msg195:  "确定",
		msg196:  "注册成功",
		msg197:  "请耐心等待工作人员进行审核",
		msg198:  "欢迎登录",
		msg199:  "请输入正确的邮箱",
		msg200:  "请输入验证码",
		msg201:  "请输入正确密码",
		msg202:  "请先阅读注册协议和隐私政策",
		msg203:  "请输入图形验证",
		msg204:  "登录成功",
		msg205:  "门窗订单",
		msg206:  "材料订单",
		msg207:  "全部",
		msg208:  "待付款",
		msg209:  "已完成",
		msg210:  "退款",
		msg211:  "订单编号",
		msg212:  "实付款",
		msg213:  "已退款",
		msg214:  "待付款",
		msg215:  "已付款",
		msg216:  "已发货",
		msg217:  "查看详情",
		msg218:  "订单详情",
		msg219:  "当前订单已退款",
		msg220:  "窗户信息",
		msg221:  "铝合金",
		msg222:  "尺寸",
		msg223:  "玻璃",
		msg224:  "配件信息",
		msg225:  "下单时间",
		msg226:  "订单信息",
		msg227:  "收货人",
		msg228:  "联系电话",
		msg229:  "收货地址",
		msg230:  "买家留言",
		msg231:  "暂无",
		msg232:  "金额明细",
		msg233:  "商品总额",
		msg234:  "运费",
		msg235:  "下单时间",
		msg236:  "支付时间",
		msg237:  "付款方式",
		msg238:  "待付款",
		msg239:  "返回",
		msg240:  "联系客服",
		msg241:  "我的资料",
		msg242:  "头像",
		msg243:  "上传照片",
		msg244:  "邮箱",
		msg245:  "昵称",
		msg246:  "编辑",
		msg247:  "保存",
		msg248:  "首页",
		msg249:  "在线商城",
		msg250:  "结算",
		msg251:  "选择收货地址",
		msg252:  "您还没有收货地址，你可以去",
		msg253:  "新增收货地址",
		msg254:  "默认地址",
		msg255:  "收货人",
		msg256:  "联系方式",
		msg257:  "收货地址",
		msg258:  "地址切换",
		msg259:  "新建收货地址",
		msg260:  "全部商品",
		msg261:  "商品信息",
		msg262:  "单价",
		msg263:  "数量",
		msg264:  "小计",
		msg265:  "件商品",
		msg266:  "商品金额",
		msg267:  "运费",
		msg268:  "应付金额",
		msg269:  "提交订单",
		msg270:  "加入购物车",
		msg271:  "选择地址",
		msg272:  "配送方式",
		msg273:  "地址选择",
		msg274:  "姓名",
		msg275:  "手机号",
		msg276:  "选择当前地址",
		msg277:  "取消",
		msg278:  "确定",
		msg279:  "自提",
		msg280:  "送货",
		msg281:  "寄车",
		msg282:  "请先选择所在地区",
		msg283:  "请先登录",
		msg284:  "添加成功",
		msg285:  "挑选商品",
		msg286:  "添加数量最小为1",
		msg287:  "购买数量最小为1",
	}
}