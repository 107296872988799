export default { 
  //英文
    msg: {
        msg1: 'Línea directa',
        msg2: 'Inicio',
        msg3: 'Contáctanos',
        msg4: 'Sin datos',
        msg5: 'Teléfono',
        msg6: 'Correo electrónico',
        msg7: 'Lista de materiales > Detalles del material',
        msg8: 'Tamaño personalizado',
        msg9: 'Por favor ingrese la longitud',
        msg10: 'Metros',
        msg11: 'Agregar',
        msg12: 'Eliminar',
        msg13: 'Por favor ingrese la altura',
        msg14: 'Cantidad',
        msg15: 'Monto a pagar',
        msg16: 'Solicitar compra',
        msg17: 'Descripción del producto',
        msg18: 'Dirección de contacto',
        msg19: 'Destinatario',
        msg20: 'Por favor ingrese el destinatario',
        msg21: 'Hombre',
        msg22: 'Mujer',
        msg23: 'Teléfono de contacto',
        msg24: 'Por favor ingrese el teléfono de contacto',
        msg25: ' ¿Necesita entrega?',
        msg26: 'Sí',
        msg27: 'No',
        msg28: 'Ubicación',
        msg29: 'Por favor seleccione',
        msg30: 'Dirección detallada',
        msg31: 'Por favor ingrese una dirección detallada: como calle, número, comunidad, edificio, etc.',
        msg32: 'Mensaje',
        msg33: 'Opcional: el contenido ingresado ya ha sido acordado con el vendedor',
        msg34: 'Enviar',
        msg35: 'Pedido exitoso',
        msg36: 'Su solicitud ha sido enviada con éxito, un representante se pondrá en contacto con usted más tarde',
        msg37: 'Contáctanos',
        msg38: 'Hora de envío',
        msg39: 'Volver al inicio',
        msg40: 'Lista de productos',
        msg41: 'Ventanas',
        msg42: 'Materiales',
        msg43: 'Ver más',
        msg44: 'Lista de productos > Detalles del producto',
        msg45: 'Color',
        msg46: 'Seleccionar',
        msg47: 'Por favor seleccione',
        msg48: 'Forma de la ventana',
        msg49: 'Aleación de aluminio',
        msg50: 'Tamaño personalizado',
        msg51: 'Por favor ingrese la altura',
        msg52: 'Por favor ingrese el ancho',
        msg53: '¿Necesita vidrio?',
        msg54: 'Nombre del vidrio',
        msg55: 'Por favor seleccione',
        msg56: 'Accesorios',
        msg57: 'Cantidad:',
        msg58: 'Monto a pagar',
        msg59: 'Solicitar compra',
        msg60: 'Descripción del producto',
        msg61: 'Seleccionar color',
        msg62: 'Cerrar',
        msg63: 'Confirmar',
        msg64: 'Por favor ingrese palabras clave para buscar',
        msg65: 'Buscar',
        msg66: 'Yuanes/Metro',
        msg67: 'Seleccionado',
        msg68: 'Seleccionar',
        msg69: 'Seleccionar forma de la ventana',
        msg70: 'Selección de aleación de aluminio',
        msg71: 'Seleccionar vidrio',
        msg72: 'Por favor ingrese el destinatario',
        msg73: 'Por favor ingrese el teléfono de contacto',
        msg74: 'Volver al inicio',
		msg75:  "Piezas",
		msg76:  "Número mínimo 1",
		msg77:  "Por favor, elija las especificaciones primero.",
		msg78:  "Introduzca la longitud del tamaño personalizado",
		msg79:  "Introduzca el ancho del tamaño personalizado",
		msg80:  "Introduzca la altura del tamaño personalizado",
		msg81:  "Por favor, introduzca el destinatario",
		msg82:  "Por favor, introduzca el número de contacto",
		msg83:  "Por favor, elija su región",
		msg84:  "Por favor, rellene la dirección detallada",
		msg85:  "Por favor, elija el color",
		msg86:  "Por favor, elija la forma de la ventana",
		msg87:  "Por favor, elija aleación de aluminio",
		msg88:  "Por favor, elija el vidrio",
		msg89:  "Selección completa",
		
		// s1翻译
		msg90:  "Gastos",
		msg91:  "Por favor seleccione",
		msg92:  "Carrito de compras",
		msg93:  "Cerrar sesión",
		msg94:  "Iniciar sesión/Registrarse",
		msg95:  "Cierre exitoso",
		msg96:  "Centro personal",
		msg97:  "Mis datos",
		msg98:  "Mis pedidos",
		msg99:  "Gestión de direcciones",
		msg100:  "Acerca de nosotros",
		msg101:  "Configuraciones",
		msg102:  "Cambiar contraseña",
		msg103:  "Cambiar correo electrónico",
		msg104:  "Acuerdo de privacidad",
		msg105:  "Acuerdo del usuario",
		msg106:  "Agregar nueva dirección",
		msg107:  "Nombre del destinatario",
		msg108:  "Ingrese el nombre del destinatario",
		msg109:  "Sr.",
		msg110:  "Sra.",
		msg111:  "Teléfono",
		msg112:  "Por favor ingrese",
		msg113:  "Ubicación",
		msg114:  "Por favor seleccione",
		msg115:  "Dirección detallada",
		msg116:  "Complete la dirección detallada: como calle, número de casa, nombre del vecindario, etc.",
		msg117:  "Establecer como dirección predeterminada",
		msg118:  "Guardar",
		msg119:  "Eliminar",
		msg120:  "Aviso",
		msg121:  "¿Confirmar eliminar esta dirección?",
		msg122:  "Cancelar",
		msg123:  "Aceptar",
		msg124:  "Por favor ingrese el nombre del destinatario",
		msg125:  "Ingrese un número de teléfono correcto",
		msg126:  "Seleccione la ubicación",
		msg127:  "Ingrese la dirección detallada",
		msg128:  "Éxito en el envío",
		msg129:  "Modificación exitosa",
		msg130:  "Eliminación exitosa",
		msg131:  "Gestión de direcciones",
		msg132:  "Agregar nueva dirección",
		msg133:  "Nombre",
		msg134:  "Modificar",
		msg135:  "Sin datos",
		msg136:  "Cambiar contraseña",
		msg137:  "Método de modificación",
		msg138:  "Modificar con verificación de la contraseña antigua",
		msg139:  "Modificar con código de verificación del correo electrónico",
		msg140:  "Contraseña antigua",
		msg141:  "Ingrese la contraseña antigua",
		msg142:  "Nueva contraseña",
		msg143:  "Ingrese la nueva contraseña",
		msg144:  "Confirmar nueva contraseña",
		msg145:  "Ingrese la contraseña de confirmación",
		msg146:  "Necesitamos verificar el correo electrónico vinculado",
		msg147:  "Código de verificación",
		msg148:  "Ingrese el código de verificación",
		msg149:  "Reenviar código después",
		msg150:  "Obtener código de verificación",
		msg151:  "Completar",
		msg152:  "Ingrese la contraseña antigua",
		msg153:  "Ingrese la nueva contraseña",
		msg154:  "Ingrese la contraseña de confirmación",
		msg155:  "Las contraseñas ingresadas no coinciden",
		msg156:  "Cambiar correo electrónico",
		msg157:  "Necesitamos verificar el correo electrónico vinculado",
		msg158:  "Nuevo correo electrónico",
		msg159:  "Ingrese el nuevo correo electrónico",
		msg160:  "Código de verificación",
		msg161:  "Ingrese un correo electrónico correcto",
		msg162:  "Cambio exitoso",
		msg163:  "Página de inicio",
		msg164:  "Carrito de compras",
		msg165:  "Todos los productos",
		msg166:  "Información del producto",
		msg167:  "Precio unitario",
		msg168:  "Cantidad",
		msg169:  "Monto",
		msg170:  "Operación",
		msg171:  "/unidad",
		msg172:  "Seleccionar todo",
		msg173:  "Proceder al pago",
		msg174:  "Total",
		msg175:  "Productos seleccionados",
		msg176:  "Unidad",
		msg177:  "Cantidad de productos mínima alcanzada",
		msg178:  "Operación exitosa",
		msg179:  "Seleccione los productos a eliminar",
		msg180:  "Seleccione los productos para pagar",
		msg181:  "Ingrese el correo electrónico",
		msg182:  "Ingrese el código de verificación",
		msg183:  "Ingrese la contraseña (6~12 letras + números)",
		msg184:  "Iniciar sesión con código",
		msg185:  "Iniciar sesión con contraseña",
		msg186:  "Iniciar sesión",
		msg187:  "Registrar cuenta",
		msg188:  "Olvidé la contraseña",
		msg189:  "Registrarse",
		msg190:  "Ya tengo una cuenta, ir a",
		msg191:  "《Acuerdo de registro del usuario》",
		msg192:  "y",
		msg193:  "《Política de privacidad》",
		msg194:  "Estoy de acuerdo",
		msg195:  "Aceptar",
		msg196:  "Registro exitoso",
		msg197:  "Por favor espere pacientemente a la verificación del personal",
		msg198:  "Bienvenido a iniciar sesión",
		msg199:  "Ingrese un correo electrónico correcto",
		msg200:  "Ingrese el código de verificación",
		msg201:  "Ingrese la contraseña correcta",
		msg202:  "Lea primero el acuerdo de registro y la política de privacidad",
		msg203:  "Ingrese el código de verificación visual",
		msg204:  "Inicio de sesión exitoso",
		msg205:  "Pedidos de puertas y ventanas",
		msg206:  "Pedidos de materiales",
		msg207:  "Todo",
		msg208:  "Pendiente de pago",
		msg209:  "Completado",
		msg210:  "Reembolsado",
		msg211:  "Número de pedido",
		msg212:  "Monto pagado",
		msg213:  "Reembolsado",
		msg214:  "Pendiente de pago",
		msg215:  "Pagado",
		msg216:  "Enviado",
		msg217:  "Ver detalles",
		msg218:  "Detalles del pedido",
		msg219:  "Pedido reembolsado",
		msg220:  "Información de la ventana",
		msg221:  "Aleación de aluminio",
		msg222:  "Dimensiones",
		msg223:  "Vidrio",
		msg224:  "Información de accesorios",
		msg225:  "Fecha de pedido",
		msg226:  "Detalles del pedido",
		msg227:  "Destinatario",
		msg228:  "Número de contacto",
		msg229:  "Dirección de envío",
		msg230:  "Comentario del comprador",
		msg231:  "Sin comentario",
		msg232:  "Detalle del monto",
		msg233:  "Monto total del producto",
		msg234:  "Costo de envío",
		msg235:  "Fecha del pedido",
		msg236:  "Fecha de pago",
		msg237:  "Método de pago",
		msg238:  "Pendiente de pago",
		msg239:  "Volver",
		msg240:  "Contactar servicio al cliente",
		msg241:  "Mis datos",
		msg242:  "Avatar",
		msg243:  "Subir foto",
		msg244:  "Correo electrónico",
		msg245:  "Apodo",
		msg246:  "Editar",
		msg247:  "Guardar",
		msg248:  "Página de inicio",
		msg249:  "Tienda en línea",
		msg250:  "Pagar",
		msg251:  "Seleccionar dirección de envío",
		msg252:  "Aún no tiene una dirección de envío, puede ir a",
		msg253:  "Agregar nueva dirección de envío",
		msg254:  "Dirección predeterminada",
		msg255:  "Destinatario",
		msg256:  "Número de contacto",
		msg257:  "Dirección de envío",
		msg258:  "Cambiar dirección",
		msg259:  "Agregar nueva dirección",
		msg260:  "Todos los productos",
		msg261:  "Información del producto",
		msg262:  "Precio unitario",
		msg263:  "Cantidad",
		msg264:  "Subtotal",
		msg265:  "Cantidad de productos",
		msg266:  "Monto total",
		msg267:  "Costo de envío",
		msg268:  "Monto a pagar",
		msg269:  "Enviar pedido",
		msg270:  "Agregar al carrito",
		msg271:  "Seleccionar dirección",
		msg272:  "Método de envío",
		msg273:  "Seleccionar dirección",
		msg274:  "Nombre",
		msg275:  "Número de teléfono",
		msg276:  "Seleccionar dirección actual",
		msg277:  "Cancelar",
		msg278:  "Aceptar",
		msg279:  "Recoger en tienda",
		msg280:  "Envío a domicilio",
		msg281:  "Encomienda",
		msg282:  "Por favor, elija su región primero",
		msg283:  "Por favor, inicie sesión primero",
		msg284:  "Se agregó con éxito",
		msg285:  "Selección de productos",
		msg286:  "El número mínimo de adiciones es 1",
		msg287:  "El número mínimo de compras es de 1",
    }
}
